@import 'variables';
@import 'responsive';

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  button {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }

  p {
    line-height: 1.5;
  }

  .full-width-field {
    width: 100%;

    input,
    select,
    textarea {
      width: 100%;
    }
  }

  .close {
    color: #fff;
    font-size: 30px;
    margin: -15px -15px 0 0;
    opacity: 1;
  }

  .modal-header {
    background: $accent;
    color: #fff;
    padding: 30px;

    .modal-title {
      font-weight: 600;
      font-size: 22px;
      margin: 0;
      border-bottom: none;
    }

    .modal-title-secondary {
      font-weight: 400;
      font-size: 20px;
      margin: 0;
    }
  }

  .modal-body {
    font-size: 20px;
    max-height: calc(100vh - 60px - #{$modal-header-height} - #{$modal-footer-height});
    overflow: auto;
    padding: 30px;
  }

  .modal-footer {
    border-top: 1px solid $accent;

    &.modal-footer-with-actions {
      padding: 30px;
      text-align: left;

      .btn {
        margin: 3px 6px 3px 0;
      }
    }

    .btn {
      margin-top: 0;
    }
  }

  &.option-modal {
    img {
      max-height: 350px;
      max-width: 100%;
    }

    table,
    th,
    tr {
      border: 1px solid #ddd;
    }

    ul {
      li {
        margin-bottom: 5px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    p {
      white-space: pre-wrap;
    }

    .option-modal-alt {
      font-weight: 600;
      margin: 20px 0;
    }

    .table-notes {
      font-size: 16px;
    }

    table {
      font-size: 16px;

      th {
        background: $accent-light;
        color: $white;
        text-align: center;
        vertical-align: top;
      }

      tr:nth-child(even) {
        td {
          background-color: $bg-grey;
        }
      }

      td {
        vertical-align: middle;
      }
    }
  }

  &.delete-project-modal {
    font-family: $font-noto;
    line-height: 33px;

    strong {
      font-size: 24px;
    }

    .well {
      margin-top: 30px;

      .well-note-header {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  &.video-modal {
    @media (max-width: 540px) {
      .modal-body {
        padding: 15px;

        .wb-mm-ctrls {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.share-modal {
      .modal-footer-with-actions {
        .btn {
          display: block;
          margin: 3px 0;
          width: 100%;
        }
      }
    }
  }
}

.create-project-reminder-modal,
.terms-and-conditions-modal {


  h1 {
    text-align: center;
  }


  .modal-header {
    padding-bottom: 20px;
  }

  .modal-body {
    padding: 25px 74px;

    ul {
      li {
        word-wrap: break-word;
        margin-bottom: 12px;
      }

      :first-child>li {
        margin-top: 8px;
      }
    }

    p {
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: #0000008A;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-group {
        margin: 0;
      }
    }

  }
}

.terms-and-conditions-modal .modal-body p {
  font-weight: 500;
  color: #000000d4;
}