.checkbox,
.radio {
  label {
    padding-left: 30px;

    input {
      &[type='radio'],
      &[type='checkbox'] {
        margin-left: -25px;
        margin-top: 4px;
        transform: scale(1.5);
      }
    }
  }
}

.radio {
  label {
    input {
      &[type='radio'],
      &[type='checkbox'] {
        margin-top: 7px;
      }
    }
  }
}

.control-label {
  color: $accent;
}

.has-error {
  .checkbox,
  .checkbox-inline,
  .control-label,
  .radio,
  .radio-inline,
  &.checkbox label,
  &.checkbox-inline label,
  &.radio label,
  &.radio-inline label {
    color: inherit;
  }

  .form-control {
    border-color: inherit;
  }

  .help-block {
    color: $danger;
    font-weight: 600;
  }
}

.help-block {
  color: #666;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

label.control-label {
  font-family: $font-lato;
  font-size: 16px;
  font-weight: 600;
}

.full-width-field {
  width: 100%;

  input,
  select,
  textarea {
    width: 100%;
  }
}

.table-form-actions {
  margin-bottom: 20px;
}

.form-actions {
  margin-top: 60px;

  .btn {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.house-type-image {
  border: $border-dark solid 1px;
  border-radius: 5px;
  max-width: 350px;
  width: 100%;
}

.input-with-helper-text {
  display: inline-block;

  .input-helper-text {
    margin-left: 5px;
    display: inline-block;
  }
}

.controller-container-with-suffix {
  display: flex;
  align-items: center;

  & > p {
    margin: 0 0 0 8px;
  }
}

.input-group {
  input:only-child {
    border-radius: 4px;
  }

  input:not(:only-child) {
    border-right: 0;
  }

  input:disabled + .input-group-addon {
    background-color: #eee;
  }

  .input-group-addon {
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    color: #373737;
    background-color: inherit;
    padding: 6px 12px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
}

.form-group-full-width-select {
  select {
    width: 100%;
  }
}

.form-group-full-width-input {
  input {
    width: 100%;
  }
}

.underline-text {
  text-decoration: underline;
}

.define-backup-control-strategy-container {
  .heat-pump-container {
    .row {
      display: flex;
      align-items: flex-end;
    }
  }
}
