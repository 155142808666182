.page-not-found {
  p {
    margin: 0 0 30px;
    white-space: pre-wrap;
  }
}

.project-menu {
  border-color: $border-dark;

  .project-menu-button {
    a {
      padding-bottom: 7px;
      padding-top: 7px;
    }
  }

  .project-menu-steps {
    .dropdown-toggle {
      text-transform: uppercase;
    }

    .dropdown-menu {
      background: $bg-grey;
      border-color: #223140;
    }
  }
}

.project-page {
  .project-status-well {
    font-size: 18px;
    font-weight: 600;
    margin-top: 38px;
  }

  .progress {
    margin-bottom: 5px;
    margin-top: 10px;
  }
}

.overview-item {
  border-top: #000 solid 1px;
  padding: 30px 0;

  &:last-of-type {
    border-bottom: #000 solid 1px;
  }

  &:nth-child(even) {
    background-color: $bg-grey;
  }

  h3 {
    margin-top: 5px;
    font-size: 24px;
  }

  .overview-item-title {
    font-size: 20px;
    font-weight: 600;
  }

  .best-practices-button-group {
    width: max-content;

    .watch-video-button {
      margin-right: 10px;

      .video-button-text {
        margin-left: 5px;
      }
    }
  }

  .well {
    margin-bottom: 0;
    margin-top: 20px;

    .well-title {
      font-size: 22px;
      font-weight: 600;
    }

    .proposition-content {
      padding-left: 20px;
    }

    svg {
      font-size: 22px;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding-bottom: 5px;

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
}

.checklist-consideration {
  margin-top: 16px;
}

@media (max-width: 767px) {
  .well {
    font-size: 16px;

    .well-title {
      font-size: 20px;
    }
  }

  .project-page {
    .project-status-well {
      .project-status-well-mobile-title {
        display: block;
      }
    }
  }
}

@media (max-width: 991px) {
  .project-page {
    .project-status-well {
      margin-top: 0;

      .project-status-well-mobile-title {
        font-weight: 500;
      }
    }
  }
}

.project-step-warning {
  padding: 30px 20px;
  white-space: pre-wrap;

  h3 {
    font-family: 'Lato Sans', sans-serif;
    margin-top: 0;

    svg {
      color: #d3080c;
      font-size: 22px;
      margin-right: 10px;
    }
  }
}