.project-identification-form {
  .installation-type {
    &>label {
      font-family: 'Lato', sans-serif;
      font-size: 24px;
      font-weight: 600;
      margin: 30px 0 0px;
    }

    p {
      margin: 0;
      font-size: 18px;
      font-weight: 400;
    }

    legend {
      float: unset;
      margin: 0;
    }

    .installation-type-items {
      fieldset {
        display: flex;

        legend {
          float: unset;
          margin: 0;
        }

        &>div {
          margin-right: 50px;
        }
      }

    }
  }

  .address, .base-case {

    &>label {
      font-family: 'Lato', sans-serif;
      font-size: 24px;
      font-weight: 600;
      margin: 0;

      &>p {
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        font-weight: 400;
        margin: 24px 0 24px 0;
      }
    }


  }

  .address-form {
    select {
      min-width: 100%;
    }
  }

  .base-case-form {
    select {
      min-width: 100%;
    }

    .base-case-name-input input {
      width: 240px;
    }

    .base-case-fuel-type-input {
      width: 240px;
    }

    .base-case-efficiency-input .base-case-efficiency-label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    .form-group {
      margin-bottom: 44px;
    }

    .form-group.has-error {
      margin-bottom: 15px;
    }
  }

  .base-case-form-accordion {
    select {
      min-width: 100%;
    }

    .base-case-name input {
      width: 100%;
    }

    margin-bottom: 20px;
  }

  p {
    margin: 0;
  }

  label {
    font-weight: 400;
  }

  label.section-label {
    font-weight: 700;
  }

  .description {
    font-size: 18px;
  }

  p.description {
    font-weight: 400;
    font-size: 18px;
  }

  p {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }
}
