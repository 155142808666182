.license-section {
  border-top: $border solid 1px;
  margin-top: 30px;
  text-align: right;
}

.license-page {
  h4 {
    text-transform: uppercase;
  }

  ol {
    counter-reset: item;
    padding-left: 10px;
  }

  ol > li {
      counter-increment: item;

      &::marker {
        content: counters(item, ".") " ";
        font-weight: bold;
      }
  }

  ol ol > li {
      display: block;
  }

  ol {
    ol {
      padding-left: 0;

      & > li:before {
        content: counters(item, ".") " ";
        font-weight: normal;
      }
    }
  }
}

@media (max-width: 991px) {
  .license-section {
    text-align: left;
  }
}
