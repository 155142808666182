@import 'variables';

.video-button {
  .video-button-text {
    margin-left: 10px;
  }
}

.btn {
  padding: 7px 12px;
  border-radius: 5px;
  font-size: 16px;

  &.btn-default {
    color: black;

    &:not(:hover) {
      border: 1px solid #DCDEE1;
    }

    &.btn-contrast {
      background-color: $accent;
      border-color: $white;
      color: $white;

      &:hover {
        background-color: $accent-light;
        color: $white;
      }
    }
  }

  &.btn-primary {
    border: 1px solid $accent;
    color: #fff;

    &.btn-contrast {
      background-color: #fff;
      color: $accent;
      border-color: #CCCCCC;
      font-weight: 600;
      // font-size: 16px;
    }
  }
}

.btn-remove-container {
  margin-right: 12px
}

.btn-icon-left {
  svg {
    margin-right: 14px
  }
}

.btn-icon-right {
  svg {
    margin-left: 14px
  }
}

.button-row {
  .btn {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 767px) {
  .button-row {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .btn {
      margin: 2px 0;
    }
  }
}