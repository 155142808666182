.heat-pump-selection-container {
  p {
    color: black;
  }

  .intro-title {
    font-size: 18px;
  }

  ul {
    font-size: 16px;
  }

  .proposition-content {
    ul {
      font-size: 20px;
    }
  }

  h2,
  h3 {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
    line-height: 27px;
  }

  hr {
    margin-bottom: 0;
    margin-top: 16px;
  }

  .nav.nav-tabs {
    a {
      border-top: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-radius: 0;
      height: 42px;
      margin-right: 10px;
    }

    li.active > a {
      border-top: 4px solid #233849;
      padding-bottom: 13px;
      padding-top: 7px;
    }

    li:not(.active) > a {
      background-color: #eaf3fc;
      border-bottom: 1px solid #ccc;
    }

    li.disabled > a {
      background-color: #eee;
    }
  }

  .tab-content {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    padding: 20px;
  }

  .filter {
    hr {
      margin-bottom: 16px;
    }

    select {
      min-width: 100%;
      max-width: 165px;
    }

    label {
      white-space: pre-wrap;
    }

    .input-group {
      width: 100%;
    }

    .filter-btn-group {
      justify-self: end;
    }

    .margin-top-larger {
      margin-top: 60px;
    }
  }

  .form-wrapper {
    border: 1px solid #ccc;
    padding: 20px;
  }

  .pagination > li > a,
  .pagination > li > span {
    padding: 10px 14px;
  }

  .heat-pumps-container {
    min-height: 674px;
  }

  .heat-pump-container {
    border: 1px solid #c4c4c4;
    margin: 0;
    max-height: 674px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      width: 10px;
      border: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: white;
      opacity: 0;
      border: 0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #26374a;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #26374a;
    }

    .heat-pump {
      padding: 0px 0px 16px 8px;
      margin: 16px 0 0 0;
      border-bottom: 1px solid #c4c4c4;
      flex: 50%;
      max-width: 50%;

      > div {
        padding-right: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;
        height: 100%;
      }

      &:nth-child(odd) {
        > div {
          border-right: 1px solid #c4c4c4;
        }
      }

      .fire-icon {
        color: #d64c1d;
        margin: 0 13px 0 5px;
      }

      p:not(.heat-pump-name) {
        margin: 0;
      }

      .rated-btuh {
        margin: 8px 0;
      }

      .model-number {
        p {
          word-break: break-word;
        }
      }

      .heat-pump-button {
        margin: 24px 0 0 0;
        height: 100%;
        display: flex;
        align-items: flex-end;

        > div {
          padding: 0;
          max-width: fit-content;
        }

        > div:first-child {
          margin-right: 8px;
        }
      }
    }

    .heat-pump-name {
      font-weight: 700;
      font-size: 18px;
    }
  }
}

.compare-list {
  p:first-child {
    font-weight: 700;
    color: black;
    font-size: 24px;
  }

  p:last-child {
    text-align: center;
  }

  hr {
    margin-bottom: 16px;
  }

  .rdt_TableRow {
    > div {
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 16px;
    }
  }

  .compare-list-rated-btuh-container,
  .compare-list-cop-container,
  .compare-list-at-temperature-container {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .heat-pump-selection-container {
    .heat-pump-container {
      .heat-pump {
        flex: 100%;

        &:nth-child(odd) {
          > div {
            border: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .heat-pump-selection-container {
    .filter {
      .form-group {
        max-width: 165px;
      }
    }
  }
}
