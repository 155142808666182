.step-page {
  .step-full-width-image {
    img {
      max-width: 100%;
    }
  }

  .check-and-copy-container {
    margin-top: 35px;

    svg {
      margin-right: 10px;
    }

    .complete-check-icon {
      width: 26px;
      height: 26px;
    }

    .align-left .copy-link-button {
      align-items: flex-start;
    }
  }

  .copy-link-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .read-only-field {
    label {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }
  }

  .page-description {
    font-size: 18px;
    line-height: 24px;
  }

  .project-step-footer {
    margin-top: 75px;

    textarea {
      width: 100%;
    }

    svg {
      margin-right: 10px;
    }

    .complete-check-icon {
      width: 26px;
      height: 26px;
    }

    .additional-info-view {
      margin-bottom: 20px;
    }
  }



  .proposition.well {
    margin-bottom: 20px;
    margin-top: 20px;

    &>div:first-child {
      &>div {
        min-height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .well-title {
      font-size: 22px;
      font-weight: 600;
    }

    .proposition-content {
      padding-left: 20px;
      line-height: 1.65em;
    }

    svg {
      font-size: 22px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        padding-bottom: 5px;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    .with-list-style {
      ul {
        list-style: disc;
      }
    }
  }

  .step-page-accordion {
    .dark-accordian-header {
      background-color: #26374a;

      h4 {
        color: #ffffff;
      }
    }
  }

  .step-type-description-container {
    p {
      white-space: pre-wrap
    }
  }

}

.step-option-radio {
  label {
    width: 100%;
  }

  .option-value {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 5px;
  }

  .option-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 5px;
  }

  .option-modal-trigger {
    margin: 10px 0 20px;
  }

  img {
    max-height: 200px;
    max-width: 100%;
  }

  .video-button {
    margin: 10px 0 20px 10px;

    .video-button-text {
      margin-left: 10px;
    }
  }
}

.project-stepper {
  margin: 70px 0 30px;

  .btn {
    padding: 5px 10px;
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.determine-heat-loss-gain-page {
  img {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .determine-heat-loss-gain-form {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0 15px;

    .form-group {
      margin-right: 10px;
    }
  }
}

.compact-image {
  max-width: 100%;
}

.cross-sectional-area-measurement-page {

  .selected-option-text {
    &>div {
      display: flex;
      justify-content: space-between;
    }
  }

  .add-trunk {
    margin-bottom: 46px;
  }

  .trunk-row {
    display: flex;
    align-items: center;

    select {
      width: 100%;
    }
  }

  .trunk-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  hr {
    margin: 0;
    border: none;
    margin-bottom: 16px;
    border-bottom: 1px solid #C4C4C4;
  }

  button {
    display: flex;
    align-items: center;
    margin-top: 12px;
  }
}

.define-backup-control-strategy-container {
  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    >div {
      flex: 25%;

      input {
        max-width: 150px;
      }

      select {
        min-width: 150px;
        max-width: 150px;
      }
    }
  }
}

.step-page-accordian-header {

  .panel-title {
    .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

    }
  }

  .panel-title {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .title-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .header {
      display: flex;
      align-items: center;

      a {
        text-decoration: none;

        &:hover,
        &:focus {
          color: #284162;
        }
      }

      h4 {
        margin: 0px;
        font-family: Noto Sans;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        padding-left: 5px;
      }

      .label {
        display: block;
      }
    }
  }
}

.utility-costs-desciption {

  img {
    width: 100%;
  }

  .tableContainer {
    overflow-x: auto;
    width: 100%;
    padding-bottom: 12px;

    table {

      th,
      td {
        border: 1px solid black;
        padding: 4px;
      }
    }
  }
}

.intermediate-results {
  display: flex;
  align-items: center;
  margin: 8px 0;

  p {
    margin: 0 16px 0 0;
  }
}