@import 'variables';

p {
  line-height: 1.5;
}

.app-name-small {
  font-size: 22px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.button-row {
   .btn {
     margin-right: 10px;

     &:last-child {
       margin-right: 0;
     }
   }
}

.page-header {
  border-bottom: none;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}
