.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg{
  text-align: right;
}

.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg{
  text-align: center;
}

@media (max-width: 767px) {
  .text-right-not-xs, .text-center-not-xs{
      text-align: left;
  }
  .text-right-xs {
      text-align: right;
  }
  .text-center-xs {
      text-align: center;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>li>a {
    white-space: break-spaces;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .text-right-not-sm, .text-center-not-sm{
      text-align: left;
  }
  .text-right-sm {
      text-align: right;
  }
  .text-center-sm {
      text-align: center;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .text-right-not-md, .text-center-not-md{
      text-align: left;
  }
  .text-right-md {
      text-align: right;
  }
  .text-center-md {
      text-align: center;
  }
}

@media (min-width: 1200px) {
  .text-right-not-lg, .text-center-not-lg{
      text-align: left;
  }
  .text-right-lg {
      text-align: right;
  }
  .text-center-lg {
      text-align: center;
  }
}
