.checklist-page {

  .btn-remove-container {
    margin-right: 16px
  }

  .checklist-accordian-header {
    .panel-title {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .title-wrapper {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      .header {
        display: flex;
        align-items: center;

        a {
          text-decoration: none;
        }

        h4 {
          margin: 0px;
          font-family: Noto Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 27px;
          padding-left: 5px;
        }

        .checkbox {
          margin: 0px;

          input {
            position: inherit;
          }
        }

        .label {
          display: block;
        }

        .mark-complete-checkbox {
          margin: 0px;
        }

        .complete-check-icon {
          margin-right: 10px;
        }

        .hidden-check-icon {
          opacity: 0;
          margin-right: 10px;
        }
      }
    }
  }

  .selected-option-text {
    font-weight: 600;

    label {
      font-size: 20px
    }

    p {
      font-size: 24px;
    }
  }

  .print-button {
    margin-top: 30px;
    margin-bottom: 25px;

    .print-icon {
      margin-right: 10px;
    }
  }

  .dark-accordian-header {
    background-color: #26374a;

    h4 {
      color: #ffffff;
    }
  }

  .additional-info-view,
  .read-only-field {
    label {
      font-weight: 600;
      font-size: 16px;
    }

    p {
      font-weight: normal;
      font-size: 20px;
    }
  }

  legend {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }
}