.dashboard-header {
  margin-bottom: 10px;
}

.project-filters {
  align-items: center;
  display: flex;

  label {
    font-size: 16px;
    font-weight: 300;
    margin-right: 10px;
  }

  .form-group {
    display: inline-flex;
    margin: 0;
  }
}

.copy-btn {
  margin-right: 10px;
}

@media (max-width: 991px) {
  .add-project-btn {
    margin-bottom: 10px;
  }

  .copy-btn {
    margin-left: 10px;
    margin-right: 0;
  }

  .project-filters {
    display: inline-block;
    width: 50%;

    &:first-of-type {
      padding-right: 5px;
    }

    &:last-of-type {
      padding-left: 5px;
    }

    label {
      display: block;
    }

    .form-group {
      display: block;

      select {
        width: 100%;
      }
    }
  }
}
