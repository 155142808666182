.navbar {
  background-color: $bg-grey;
  color: $accent;
  font-weight: 600;

  .navbar-toggle {
    .navbar-collapse-close {
      display: inline-block;
    }

    .navbar-collapse-open {
      display: none;
    }
  }

  .collapsed {
    .navbar-collapse-close {
      display: none;
    }

    .navbar-collapse-open {
      display: inline-block;
    }
  }

  .navbar-collapse,
  .container {
    padding: 0;
  }

  .dropdown {
    .dropdown-toggle {
      svg {
        margin-left: 10px;
      }
    }

    .dropdown-toggle:focus {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
      outline-width: 5px;
      outline-offset: -2px;
    }

    &.open {
      .dropdown-toggle {
        background-color: $accent-light;
        color: $white;
      }

      &>li {
        &>a {
          background-color: $accent-light;
          color: $white;

          &:hover {
            background-color: $accent-light;
            color: $white;
          }
        }
      }
    }

    .dropdown-menu {
      border-radius: 4px;
      margin-top: 5px;
      padding: 0;

      li {
        width: 100%;

        a {
          color: $accent;
          padding: 15px;
        }
      }
    }
  }

  .navbar-toggle {
    color: $accent;
    text-align: center;
    width: 60px;

    &:not(.collapsed),
    &:focus {
      background-color: transparent;
      color: $accent-light;
    }

    &:hover {
      background-color: $accent-light;
      color: $white;
    }
  }

  .navbar-nav {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding: 0;

    &>li {
      &>a {
        padding: 20px;
      }

      &.project-menu-button {
        a {
          padding: 14px
        }
      }
    }

    &,
    .dropdown-menu {
      &>li {
        &>a {
          color: $accent;
          font-size: 20px;
          font-weight: 600;

          &:hover {
            background-color: $accent-light;
            color: $white;
          }
        }
      }
    }
  }

  .navbar-header {
    align-items: center;
    display: flex;
    height: 60px;
    margin: 0;

    .mobile-menu-left {
      align-items: center;
      display: flex;
      height: 100%;
    }

    .navbar-toggle {
      border: none;
      float: none;
      height: 100%;
      margin: 0;
      padding: 0 20px;
    }

    .mobile-menu-right {
      height: 100%;
    }

    .mobile-help {
      justify-self: flex-end;
      height: 100%;

      a {
        align-items: center;
        display: inline-flex;
        height: 100%;

        svg {
          margin-right: 10px;
        }
      }
    }

    .navbar-brand {
      color: $accent;
      float: none;
      padding-left: 10px;
    }

    .mobile-menu-right {
      flex: 1;
      text-align: right;
    }
  }

  .navbar-collapse {
    border: none;
    box-shadow: none;
    margin: 0;
  }

  .container-fluid {
    padding: 0;
  }
}

.navbar-inverse {
  background: $accent;

  .navbar-nav,
  .navbar-nav .dropdown-menu {
    li {
      a {
        color: $white;

        &:hover {
          background-color: $accent-light;
        }
      }
    }

    &>.open {

      &>a,
      &>a:focus,
      &>a:hover {
        background-color: $accent-light;
      }
    }
  }

  .navbar-header {
    .navbar-brand {
      color: $white;
    }

    .navbar-toggle {
      color: $white;

      &:hover {
        background-color: $accent-light;
      }
    }
  }

  .dropdown-menu {
    background-color: $accent;

    &>li {
      a {
        color: $white;

        &:hover {
          background-color: $accent-light;
        }
      }
    }
  }
}

.main-menu {
  margin-bottom: 40px;

  .menu-help {
    a {
      align-items: center;
      display: flex;
    }

    .menu-help-icon {
      font-size: 22px;
      margin-right: 10px;
    }
  }

  .dropdown-menu {
    &>li>a:focus {
      background-color: $accent;
      color: $white;
    }
  }
}

.project-menu {
  .project-menu-button {
    margin-top: 11.5px;
    margin-bottom: 11.5px;
  }

  .dropdown {
    .dropdown-menu {
      max-width: 70vw;
      min-width: 600px;
      width: 100vw;

      li {
        a {
          white-space: pre-wrap;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .navbar {
    border-radius: 4px;

    .navbar-header {
      float: none;
    }

    .navbar-left,
    .navbar-right {
      float: none !important;
    }

    .navbar-toggle {
      display: block;
    }

    .navbar-fixed-top {
      border-width: 0 0 1px;
      top: 0;
    }

    .navbar-collapse.collapse {
      display: none !important;
    }

    .navbar-nav {
      float: none !important;
    }

    .navbar-nav>li {
      float: none;
    }

    .navbar-nav>li>a {
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .collapse.in {
      display: block !important;
    }

    .navbar-brand {
      margin-left: 0;
    }

    .navbar-nav .dropdown {
      .dropdown-menu {
        min-width: 0;

        li {
          a {
            padding: 10px 10px 10px 40px;
          }
        }
      }

      &.open {
        .dropdown-menu {
          position: static;
          float: none;
          width: auto;
          margin-top: 0;
          background-color: transparent;
          border: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
          max-width: 100%;
        }
      }
    }
  }

  .project-menu {
    .project-menu-button {
      float: none;
      margin-right: 15px;
    }
  }
}